import {
  availableComponents,
  componentDataMappers,
  defaultOrder,
} from './componentMapping/homePageComponents';
import { mapCustomClassesToComponent } from './themes/customTheme';
import { DO_NOT_RENDER } from './constants';

import type {
  AvailableHomePageData,
  MappedComponents,
  MappedKey,
} from './componentMapping/homePageComponents';

export type TConditionalRender<T> = ({ [P in keyof T]?: T[P] } & typeof DO_NOT_RENDER) | T;

const serializeJson = (data: object) => JSON.parse(JSON.stringify(data));

/**
 * This function creates an array of objects by mapping `components`
 * to their associated data mapper. If `components` is empty, the
 * default component order will be used.
 *
 * The returned mappedComponents array will be used by the `renderComponents`
 * function to render components on the page with any custom wrapper class
 * name that is defined in `customTheme`.
 *
 * Future state will take in page name to make this function more dynamic.
 *
 * Important: When building data mappers, make sure to only map data that is
 * required for the component to render/function.
 *
 * Mapping should happen server-side, so the less data we need to send via
 * `getServerSideProps`, the better.
 *
 * @param components array of Home page components to be rendered.
 * @param data all data required to successfully render the Home page components.
 * @returns [ { `componentName`, `props` } ]
 */
export const mapHomePageDataToComponentProps = (
  components: MappedKey[],
  data: AvailableHomePageData
): { componentName: MappedKey; props: MappedComponents }[] =>
  serializeJson(
    (components.length ? components : defaultOrder).flatMap((component: MappedKey) => {
      const renderData = componentDataMappers[component](data);

      if (renderData === DO_NOT_RENDER) {
        return [];
      }

      return {
        componentName: component,
        props: {
          ...renderData,
          wrapperClass: mapCustomClassesToComponent(component, data.layout.brandCode ?? ''),
        },
      };
    })
  );

/**
 * This function creates an array of JSX.Elements to be rendered on the page
 * by mapping component names and props to Components.
 *
 * @returns an array of JSX.Elements to be rendered on the page.
 */
export const renderComponents = (
  mappedComponents: { componentName: MappedKey; props: MappedComponents }[]
): React.JSX.Element[] =>
  mappedComponents.map(({ componentName, props }) => {
    const Component = availableComponents[componentName] as React.FC;

    return <Component {...props} key={componentName} />;
  });
