import type { HotelAddress, HotelCoordinate, Maybe } from '@dx-ui/gql-types';
import { searchEngineOptimizedImages } from '../../helpers/property-utils';
import type { GetHotelRoomsPageQuery } from '../../generated/types';

type CommonSchema = {
  description: string;
  name?: Maybe<string>;
  url: string;
  schemaType?: string;
};

export type GallerySchema = {
  images: string[];
} & CommonSchema;

export type GolfSchema = {
  images: string[];
  amenities: string[];
  containedIn: string;
} & CommonSchema;

export type StandardSchema = {
  amenityFeature?: string[];
  openingHours: string;
  telephone: string | null | undefined;
  image: string | null | undefined;
  address: HotelAddress | null | undefined;
  coordinates: HotelCoordinate | null | undefined;
  checkinTime?: string;
  checkoutTime?: string;
} & CommonSchema;

export type HotelInfoSchema = {
  currenciesAccepted: string[];
  amenityFeature: string[] | undefined;
} & CommonSchema;

export type RoomsSchema = {
  containsPlace: NonNullable<GetHotelRoomsPageQuery['hotel']>['roomTypes'];
} & CommonSchema;

export type SpaSchema = {
  address: HotelAddress;
  containedIn: string;
  email: string;
  images?: string[];
  openingHours: string[];
  telephone: string;
} & CommonSchema;

export type SeoSchema =
  | StandardSchema
  | HotelInfoSchema
  | GallerySchema
  | GolfSchema
  | RoomsSchema
  | SpaSchema;

const mapToAddressSchema = (address: StandardSchema['address']) => ({
  address: {
    '@type': 'PostalAddress',
    streetAddress: address?.addressLine1,
    addressLocality: address?.city ?? address?.mapCity,
    addressRegion: address?.state,
    postalCode: address?.postalCode,
    addressCountry: address?.country,
  },
});

export function getGallerySchema(gallerySchema: GallerySchema) {
  return {
    '@context': 'http://schema.org',
    '@type': gallerySchema.schemaType,
    description: gallerySchema.description,
    name: gallerySchema.name,
    url: gallerySchema.url,
    mainEntityOfPage: {
      image: gallerySchema.images.map((img) => ({
        '@type': 'ImageObject',
        url: img,
      })),
    },
  };
}

export function getGolfSchema(golfSchema: GolfSchema) {
  return {
    '@context': 'http://schema.org',
    '@type': golfSchema.schemaType,
    description: golfSchema.description,
    name: golfSchema.name,
    url: golfSchema.url,
    image: golfSchema.images,
    amenityFeature: golfSchema?.amenities?.map((amenity) => ({
      '@type': 'LocationFeatureSpecification',
      name: amenity,
    })),
    containedInPlace: {
      '@type': 'Place',
      name: golfSchema.containedIn,
      url: golfSchema.url,
    },
  };
}

export function getHomeSchema(homeSchema: StandardSchema) {
  return {
    '@context': 'http://schema.org',
    '@type': homeSchema.schemaType,
    amenityFeature: homeSchema.amenityFeature?.map((amenity) => ({
      '@type': 'LocationFeatureSpecification',
      name: amenity,
    })),
    description: homeSchema.description,
    name: homeSchema.name,
    url: homeSchema.url,
    ...mapToAddressSchema(homeSchema.address),
    checkinTime: homeSchema.checkinTime,
    checkoutTime: homeSchema.checkoutTime,
    image: homeSchema.image,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: homeSchema.coordinates?.latitude,
      longitude: homeSchema.coordinates?.longitude,
    },
    openingHours: homeSchema.openingHours,
    telephone: homeSchema.telephone,
  };
}

export function getHotelInfoSchema(hotelInfoSchema: HotelInfoSchema) {
  return {
    '@context': 'http://schema.org',
    '@type': hotelInfoSchema.schemaType,
    description: hotelInfoSchema.description,
    name: hotelInfoSchema.name,
    url: hotelInfoSchema.url,
    currenciesAccepted: hotelInfoSchema.currenciesAccepted,
    amenityFeature: hotelInfoSchema.amenityFeature?.map((amenity) => {
      return {
        '@type': 'LocationFeatureSpecification',
        name: amenity,
      };
    }),
  };
}

export function getSpaSchema(spaSchema: SpaSchema) {
  return {
    '@context': 'http://schema.org',
    '@type': spaSchema.schemaType,
    ...mapToAddressSchema(spaSchema.address),
    containedInPlace: {
      '@type': 'Place',
      name: spaSchema.containedIn,
      url: spaSchema.url,
    },
    image: spaSchema.images,
    description: spaSchema.description,
    openingHours: spaSchema.openingHours,
    name: spaSchema.name,
    url: spaSchema.url,
  };
}

export function getRoomsSchema(roomsSchema: RoomsSchema) {
  return {
    '@context': 'http://schema.org',
    '@type': roomsSchema.schemaType,
    name: roomsSchema.name,
    containsPlace: roomsSchema.containsPlace.map((room) => ({
      '@type': 'HotelRoom',
      name: room.roomTypeName,
      image: searchEngineOptimizedImages(room.carousel),
      description: room.roomTypeDesc?.replace(/(<([^>]+)>)/gi, ''),
      bed: {
        '@type': 'BedDetails',
        typeOfBed: room.bedType,
        numberOfBeds: room.numBeds,
      },
      occupancy: {
        '@type': 'QuantitativeValue',
        maxValue: room.maxOccupancy,
      },
      amenityFeature: room.features.map((feature) => ({
        '@type': 'LocationFeatureSpecification',
        value: true,
        name: feature.name,
      })),
    })),
  };
}
